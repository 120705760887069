import React, { useRef, useState } from "react";
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react";

import { navigate } from 'gatsby';

import Grid from '@mui/material/Grid';


import Breadcrumbs from '@mui/material/Breadcrumbs';

import "swiper/css";

import {Navigation } from "swiper";
import Paper from '@mui/material/Paper';



import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

import { useTheme } from '@mui/material/styles';

import { MdOutlineSwipeLeft } from "react-icons/md/";
import { MdOutlineSwipeRight } from "react-icons/md";

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const reviewImages = [
  {
    label: "Picture of of the newspaper logo The Telegraph in the context of the newspaper's review of E.O. Chirovici's Bad Blood ",
    text: '… a must-read for those who are involved in the act of leadership  ',

    imgPath:
      '/images/reviews/kraus.jpg',
  },
    {
    label: "Picture of of the newspaper logo The Telegraph in the context of the newspaper's review of E.O. Chirovici's Bad Blood ",
    text: 'This book reveals the dilemma of using military or economic power to subjugate human resistance. … It should serve as a valuable analytic contribution to the recorded history of mankind  ',

    imgPath:
      '/images/reviews/jackson.jpg',
  }
 
];








export default function App(props) {

  

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = reviewImages.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };




  return (

      <>
  <Swiper
        className='bookPage'
        spaceBetween={0}

                centeredSlides={false}



        grabCursor={false}
          resistance={false}  



initialSlide={1}
  onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;

window.scrollTo(0,0)


              if (swiperCore.realIndex === 2) {    navigate('/sleeping-dogs');    };

              if (swiperCore.realIndex === 0) {    navigate('/books/rumours');    };





        }}



        pagination={{
          clickable: true,
        }}
      >



              <SwiperSlide  className=' '> </SwiperSlide>





             <SwiperSlide className='white switchToSLC books'> 





<div className='longPage'>




<span className='standardIntroductionText standardBookIntroductionText animate__animated'>


    <Breadcrumbs aria-label="breadcrumb">
        <Link className='breadcrumbLink' to="/">
          Home
        </Link>
          <Link className='breadcrumbLink' to="/books">
          Books
        </Link>
        <Link className='breadcrumbLink' to="/books/gods-weapons-and-money">
          Gods, Weapons and Money
        </Link>




      </Breadcrumbs>



<h3  className=' animate__animated'>Gods, Weapons and Money</h3>

</span>


<Grid className='bookGrid' container spacing={2}>

  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>Non-Fiction </span>


  </Grid>

  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>2018 </span>


  </Grid>
  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>Nortia Press </span>


  </Grid>









  </Grid>







<span className='standardIntroductionText animate__animated'>

<p className='animate__animated'>

In Gods, Weapons and Money, Eugen O. Chirovici takes us on a journey to answer the most basic but profound questions about the sources of human influence.




</p>
</span>




  <Box className='reviewBox linePicture swiper-no-swiping' sx={{ maxWidth: 400, flexGrow: 1 }}>
  


<AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {reviewImages.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (

<div>
<span className='reviewText'> {step.text} </span>

<br/><br/>

              <Box
                component="img"
                sx={{
                  height: 140,
                  display: 'inline-block',
                  maxWidth: 190,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
          </div>

            )  : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        variant='progress'
    
      />
    </Box>



<div className='bookNavigationButtons'>


<Link className='bookpageButton Left' to='/books/rumours'>

<MdOutlineSwipeLeft />

  Rumours


</Link>

<Link className='bookpageButton Right' to='/sleeping-dogs'>

Sleeping Dogs

<MdOutlineSwipeRight />


</Link>


</div>






</div>




</SwiperSlide>




























      <SwiperSlide  className=''> </SwiperSlide>

</Swiper>
       </> 
            
  );
}